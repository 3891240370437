import React from 'react'
import useStickyState from '../Hooks/useStickyState';
import useSecureStickyState from '../Hooks/useSecureStickyState';
import useCompressedStickyState from './useCompressedStickyState';


export const StoreContext = React.createContext(null)

export default ({ children }) => {

  function hashCode(s) {
    for(var i = 0, h = 0; i < s.length; i++)
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
        return h;
  }
  const dt_formatted_string = new Date(Date.now()).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
});

  const [dialogContent, setDialogContent] = React.useState({})
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [sharing, setSharing] = React.useState([])
  const [help, setHelp] = React.useState([])

  const [statuscolorTimecard, setStatuscolorTimecard] = useStickyState('', 'statuscolor');
  const [loadedmodal, setLoadedmodal] = useStickyState('', 'loadedmodal' + hashCode('loadedmodal'));
  const [donecustom, setDonecustom] = useStickyState([], 'donecustom');
  const [doneselect, setDoneselect] = useStickyState([], 'doneselect');
  const [savedactivities, setsavedactivities] = useStickyState([], 'savedactivities');
  const [doneworkout, setDoneworkout] = useStickyState([], 'doneworkout');
  const [savedhashes, setSavedhashes] = useStickyState([], 'savedhashes');
  const [todos, setTodos] = useStickyState([], 'todos'); //useCompressedStickyState([], 'todos'); //useSecureStickyState([], 'todos');
  const [todoHeader, setTodoHeader] = useStickyState('To-do', 'todolistheader');
  //const [todoHeader, setTodoHeader] = useStickyState('To-do today', 'todolistheader');
  const [todolistid, settodolistid] = useStickyState('', 'todolistid');
  const [showaddactivity, setShowaddactivity] = useStickyState(false, 'showaddactivity');
  const [clockedin, setClockedin] =  useStickyState(false, 'clockedin');
  const [sessionid, setSessionid] = useStickyState('', 'sessionid');
  const [deviceid, setDeviceid] = useStickyState('', 'deviceid');
  const [locationid, setLocationid] = useStickyState('', 'locationid');
  const [userid, setUserid] = useStickyState('', 'userid');
  const [tcsessionid, setTcsessionid] = useStickyState('', 'tcsessionid');
  const [kvtodolistnames, setKvtodolistnames] = useStickyState([], 'kvtodolistnames');
  const [sessiondata, setsessiondata] = useStickyState({}, 'sessiondata');
  const [interactiondata, setinteractiondata] = useStickyState({}, 'interactiondata'); 
  
  const store = {
    share: [sharing, setSharing],
    help: [help, setHelp],
    modaldialog: [loadedmodal, setLoadedmodal],
    mapdialog: [dialogContent, setDialogContent],
    mapdialogopen: [dialogOpen, setDialogOpen],
    activitiescustom: [donecustom, setDonecustom],
    activitiesselected: [doneselect, setDoneselect],
    hashlistsaved: [savedhashes, setSavedhashes],
    todolistheader: [todoHeader, setTodoHeader],
    todolistheaderid: [todolistid, settodolistid],
    todolistsaved: [todos, setTodos],
    activitiessaved: [savedactivities, setsavedactivities],
    activitiesclockedin: [clockedin, setClockedin],
    thisdeviceid: [deviceid, setDeviceid],
    thislocationid: [locationid, setLocationid],
    thissessionid: [sessionid, setSessionid],
    mysessiondata: [sessiondata, setsessiondata],
    myinteractions: [interactiondata, setinteractiondata],
    mytodolistnames: [kvtodolistnames, setKvtodolistnames],
    thisuserid: [userid, setUserid],
    timecardsessionid: [tcsessionid, setTcsessionid],
    activitiesaddvisible: [showaddactivity, setShowaddactivity],
    timecard: [statuscolorTimecard, setStatuscolorTimecard],
    exercisestore: [doneworkout, setDoneworkout],
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}