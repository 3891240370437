
import React from 'react';
import { StoreContext } from '../Hooks/store';

function useUserInteractionTracker() {
  const { mysessiondata, myinteractions, thissessionid } = React.useContext(StoreContext);
  //const [sessiondata, setsessiondata] = mysessiondata;
  const [interactiondata, setinteractiondata] = myinteractions;
  const [sessionid ] = thissessionid;

  const trackInteraction = (interactionType, additionalData = {}) => {
    const newInteraction = {
      type: interactionType,
      sessionHash: sessionid ?? additionalData.sessionHash,
      timestamp: Date.now(),
      data: additionalData,
    };

    const updatedInteractionData = {
      ...setinteractiondata,
      interactions: [...(interactiondata.interactions || []), newInteraction]
    };

    // const updatedSessionData = {
    //   ...sessiondata,
    //   interactions: [...sessiondata.interactions]
    // };

    // if (typeof newInteraction === 'object' && newInteraction !== null) {
    //   //updatedSessionData.interactions.push(newInteraction);
    //   if (Array.isArray(sessiondata.interactions)) {
    //     // It's an array, you can push new interactions
    //     updatedSessionData.interactions.push(newInteraction);
    //   } else {
    //     console.error('sessiondata.interactions is not an array:', sessiondata.interactions);
    //     // Handle the error, e.g., log it, throw an exception, or initialize an empty array
    //     updatedSessionData.interactions = [newInteraction];
    //   }
    // } else {
    //   console.error('Invalid interaction data:', newInteraction);
    // }

    //setsessiondata(updatedSessionData);
    setinteractiondata(updatedInteractionData);
  };

  const clearSessionData = () => {
    localStorage.removeItem('sessiondata');
    localStorage.removeItem('interactiondata');
    localStorage.removeItem('sessionid');
  };

  return {
    trackInteraction,
    clearSessionData
  };
}

export default useUserInteractionTracker;