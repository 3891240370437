import React, { useState, useEffect, useRef, Suspense } from 'react';
import { StoreContext } from '../Hooks/store';

import { notify } from "../Notifications";



import { v4 as uuidv4 } from "uuid";
import { hashCode } from '../Utils/hashCode';

import { List, ListItem, ListItemText, Checkbox, TextField, Button, ButtonGroup, Divider, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';

import IconButton from '@mui/material/IconButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import './TodoList.module.css'; // Import the CSS module
import sqlitedb from '../sqlitedb';
import { daDK } from '@mui/material/locale';


const dt_formatted_string = new Date(Date.now()).toLocaleDateString('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric'
});

export default function Todos() {
  useEffect(() => {
    notify('Loading Todos....')
    getTodos()
    //todo retrieve key-value pairs and store in cache
  },
    []
  );

  const {
    todolistsaved,
    todolistheader,
    todolistheaderid,
    thisdeviceid,
    thislocationid,
    thissessionid,
    thisuserid,
    mysessiondata,
    mytodolistnames,
    modaldialog,
  } = React.useContext(StoreContext);
  const [loadedmodal, setLoadedmodal] = modaldialog;
  const [todos, setTodos] = todolistsaved;
  const [deviceid, setDeviceid] = thisdeviceid;
  const [locationid, setLocationid] = thislocationid;
  const [sessionid, setSessionid] = thissessionid;
  const [userid, setUserid] = thisuserid;
  //const [sessiondata, setsessiondata] = mysessiondata;
  const [kvtodolistnames, setKvtodolistnames] = mytodolistnames;

  const [newTodo, setNewTodo] = useState('');
  const [isAddingTodo, setIsAddingTodo] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTodoIndex, setEditingTodoIndex] = useState(null);
  const [deletingTodoIndex, setDeletingTodoIndex] = useState(null);
  const [deletingTodoText, setDeletingTodoText] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isEditingHeader, setIsEditingHeader] = useState(false);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [isDeletingTodo, setIsDeletingTodo] = useState(false);


  const [todoHeader, setTodoHeader] = todolistheader;
  const [todolistid, settodolistid] = todolistheaderid;

  const completedTodos = todos.filter(todo => todo.completed).length;

  const handleEditHeader = () => {
    setIsEditingHeader(true);
  };

  const handleSaveHeader = () => {
    assignTodolistHeaderId();
    // Update the header text in your state or database
    setIsEditingHeader(false);
    cacheListname();
  };

  const handleCancelHeaderEdit = () => {
    setIsEditingHeader(false);
    setTodoHeader('To-do'); // Reset to default header text
  };

  const handleArchiveTodoList = () => {
    notify('Coming soon... list archiving');
    setTodoHeader('To-do'); // Reset to default header text
    //setTodoHeader('To-do today'); // Reset to default header text
    settodolistid('')

    // clear todolistheaderid
    /*
    // Call your API to archive the todo list
    archiveTodoList()
      .then(() => {
        // Clear the todo list after successful archiving
        setTodos([]);
        notify('Todo list archived');
      })
      .catch(error => {
        console.error('Error archiving todo list:', error);
        notify('Error archiving todo list');
      });
      */
  };

  // Function to handle discarding the todo list
  const handleDiscardTodoList = () => {
    setIsDiscarding(true);
  };

  const handleConfirmDiscard = () => {
    // Call your API to archive the todo list
    /*
    archiveTodoList()
      .then(() => {
        // Clear the todo list after successful archiving
        setTodos([]);
        setIsDiscarding(false);
        notify('Todo list archived');
      })
      .catch(error => {
        console.error('Error archiving todo list:', error);
        notify('Error archiving todo list');
        setIsDiscarding(false);
      });
      */
    discardTodos();
    // After successful archiving, clear the todo list and reset header 
    setTodos([]);
    setIsDiscarding(false);
    setTodoHeader('To-do'); // Reset to default header text
    //setTodoHeader('To-do today'); // Reset to default header text
    settodolistid('')
    notify('Archive complete');
  };

  const handleCancelDiscard = () => {
    setIsDiscarding(false);
  };

  const todoInputRef = useRef(null);
  const cancelDeleteTodoButtonRef = useRef(null);

  const handleAddingTodo = () => {
    setIsAddingTodo(true);
    if (todoInputRef.current) {
      todoInputRef.current.focus();
    }
  };

  const handleAddTodo = () => {
    if (newTodo.trim() !== '') {
      assignTodolistHeaderId();
      let addtodo = {
        todo_id: hashCode(uuidv4()),
        list_id: todolistid,
        list_name: todoHeader,
        description: newTodo,
        added_at: Date.now(),
        updated_at: Date.now(),
        completed_at: -1,
        completed: false,
        user_id: userid,
        session_id: sessionid,
        device_id: deviceid,
        location_id: locationid,
        active: true,
      };
      setTodos([...todos, addtodo]);
      setNewTodo('');
      setIsAddingTodo(false);
    }
  };

  const handleToggleTodo = (index) => {
    const updatedTodos = [...todos];
    updatedTodos[index] = {
      ...updatedTodos[index],
      completed_at:
        (!updatedTodos[index].completed && updatedTodos[index].completed_at === -1)
          ? Date.now()
          : updatedTodos[index].completed_at,
      completed: !updatedTodos[index].completed,
      updated_at: Date.now(),
    };
    setTodos(updatedTodos);
    notify('Edits Saved');
  };

  const handleSaveTodo = (index) => {
    const updatedTodos = [...todos];
    updatedTodos[index] = {
      ...updatedTodos[index],
      updated_at: Date.now(),
    };
    setTodos(updatedTodos);
    setEditingTodoIndex(null);
    setIsEditing(false);
    notify('Changes Saved');
  };


  const handleConfirmDeleteTodo = (index) => {
    const updatedTodos = [...todos];
    // updatedTodos.splice(index, 1);
    updatedTodos[index] = {
      ...updatedTodos[index],
      updated_at: Date.now(),
      active: false,
    };
    setTodos(updatedTodos);
    setDeletingTodoIndex(null);
    setDeletingTodoText(null);
    setIsAlertOpen(false); // Reset isAlertOpen
    setIsDeletingTodo(false);

    notify('Item removed');
  };

  const handleCancelDeleteTodo = () => {
    setDeletingTodoIndex(null);
    setDeletingTodoText(null);
    setIsAlertOpen(false); // Reset isAlertOpen
    setIsDeletingTodo(false);

    notify('Canceled');
  };


  const handleDeleteTodo = (index) => {
    setIsAlertOpen(true);
    setIsDeletingTodo(true);
    setDeletingTodoIndex(index);
    setDeletingTodoText(todos[index].description);
  };

  const handleEditTodo = (index) => {
    setEditingTodoIndex(index);
    setIsEditing(true);
  };



  useEffect(() => {
    if (isAlertOpen) { // when deleting
      // Ensure confirmation dialog is always on top (even when scrolled)
      document.body.style.overflow = 'hidden';

      // Scroll to the confirmation dialog when it opens
      const confirmationElement = document.querySelector('.confirmation-dialog');
      if (confirmationElement) {
        confirmationElement.scrollIntoView({ behavior: 'smooth' });
      }

      if (cancelDeleteTodoButtonRef.current) { // set focus to Cancel button
        cancelDeleteTodoButtonRef.current.focus();
      }
    } else {
      document.body.style.overflow = 'auto'; // Restore default scroll behavior
    }
  }, [isAlertOpen]);

  const assignTodolistHeaderId = () => {
    if (!todolistid || todolistid === '') {
      let todoListHeaderId = hashCode(uuidv4());
      console.log(`todoListHeaderId=${todoListHeaderId}`);
      settodolistid(todoListHeaderId); // note that this updates todolistid
    }
  }

  function cacheListname() {
    let addtodolistname = {
      list_id: todolistid,
      list_name: todolistheader,
    };

    console.dir(addtodolistname, { depth: null });
    console.table(addtodolistname)

    const updatedKvtodolistnames = [...kvtodolistnames];
    if (updatedKvtodolistnames && updatedKvtodolistnames.length < 1) {
      updatedKvtodolistnames.push(addtodolistname);
    }
    // else {
    //   const newKvtodolistname = {
    //     [todolistid]: todolistheader
    //   };

    //   // Find the existing index
    //   const existingIndex = kvtodolistnames.findIndex(item => item.list_id === todolistid);

    //   // Update or add the new list name
    //   //const updatedKvtodolistnames = [...kvtodolistnames];
    //   if (existingIndex !== -1) {
    //     updatedKvtodolistnames[existingIndex] = newKvtodolistname;
    //   } else {
    //     updatedKvtodolistnames.push(newKvtodolistname);
    //   }
    // }

    setKvtodolistnames(updatedKvtodolistnames);
  }

  async function discardTodos() {
    return await sqlitedb.deleteTodos(userid, todolistid);
  }

  async function getTodos() {
    try {
      let recentTodos = [];
      if (todolistid) {
        console.log('list-id set, will fetch remote by userid and todolistid');
        console.time('fetchExistingTodos')
        recentTodos = await sqlitedb.fetchExistingTodos(userid, todolistid);
        console.timeEnd('fetchExistingTodos')

      } else {
        console.log('list-id NOT set, will fetch remote by userid only');
        console.time('fetchRecentTodos')
        recentTodos = await sqlitedb.fetchRecentTodos(userid)
        console.timeEnd('fetchRecentTodos')
      }

      if (!recentTodos || recentTodos.length === 0) {
        // Handle the case where no todos are found
        notify('No todos found for user')
        console.warn('No todos found for user:', thisuserid);
        return;
      }


      if (recentTodos.length > 0) {
        settodolistid(recentTodos[0].list_id)
      }

      console.log('recentTodos table :')
      console.table(recentTodos)
      // console.dir(recentTodos, { depth: null })

      setTodos(recentTodos);
    } catch (error) {
      console.error('Error fetching todos:', error);
      notify(error.message);
    }
  }

  function Loading() {
    return <h2>🌀 Loading...</h2>;
  }

  return (
    <>
      {isDiscarding ? (
        <div className="confirmation-dialog">
          {/* Confirmation dialog content */}
          <Typography variant="h6" component="h2">Discard this list?</Typography>
          <Typography variant="subtitle2" component="h6">Name: {todoHeader}</Typography>
          <Divider />

          <div style={{ marginTop: '32px' }}>
            <Typography variant="body1">This action cannot be undone!</Typography>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
            <Button variant="outlined" onClick={handleCancelDiscard}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleConfirmDiscard} style={{ marginLeft: 'auto' }}>
              Discard
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div style={{ height: 400, width: '100%', visibility: isAlertOpen ? 'hidden' : 'visible' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isEditingHeader ? (
                <>
                  <TextField
                    value={todoHeader}
                    onChange={(e) => setTodoHeader(e.target.value)}
                    onBlur={handleSaveHeader}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSaveHeader();
                      }
                    }}
                    autoFocus
                  />
                  <Tooltip title="Save the name of this list">
                    <SaveOutlinedIcon onClick={handleSaveHeader} />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Typography variant="h6" component="h2" onClick={handleEditHeader}>
                    {todoHeader}
                  </Typography>
                  <Tooltip title="Update the Name of this list" className="hide-on-mobile">
                    <IconButton aria-label="Update the Name of this list" onClick={handleEditHeader} className="hide-on-mobile">
                      <EditNoteOutlinedIcon className="hide-on-mobile" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>

            <div style={{ display: 'flex', visibility: isAlertOpen ? 'hidden' : 'visible' }}>
              {isAddingTodo ? (
                <></>
              ) : (

                <div style={{ display: 'flex', alignItems: 'center' }}>

                  <Tooltip title="Add to the list">
                    <IconButton aria-label="Add to the list" onClick={handleAddingTodo}>
                      <AddOutlinedIcon variant="contained" onClick={handleAddingTodo} />
                    </IconButton>
                  </Tooltip>
                  &nbsp;&nbsp;
                  {todos.length > 1 && (
                    <Tooltip title={`${completedTodos} of ${todos.length} completed`}>
                      <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                        {completedTodos} of {todos.length} completed.
                      </span>
                    </Tooltip>
                  )}

                  <>
                    {todos.length > 0 && (
                      <ButtonGroup variant="outlined" aria-label="Loading button group">
                        <Tooltip title="Archive this list">
                          <IconButton aria-label="Archive this list" onClick={handleArchiveTodoList}>
                            <InventoryOutlinedIcon color="success" onClick={handleArchiveTodoList} />
                          </IconButton>
                        </Tooltip>
                        &nbsp;&nbsp;&nbsp;
                        <Tooltip title={`Discard list and its contents`}>
                          <IconButton aria-label={`Discard "${todoHeader}"`} onClick={handleDiscardTodoList}>
                            <DeleteForeverOutlinedIcon color="error" onClick={handleDiscardTodoList} />
                          </IconButton>
                        </Tooltip>

                      </ButtonGroup>
                    )}
                  </>
                </div>

              )}
            </div>

            <hr style={{ marginTop: '2px' }} />

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '2px', marginBottom: '2px' }}>

              {isAddingTodo ? (
                <>
                  <TextField
                    label="Add Todo"
                    ref={todoInputRef}
                    value={newTodo}
                    onChange={(e) => setNewTodo(e.target.value)}
                    onBlur={() => {
                      if (newTodo.trim() === '') {
                        setIsAddingTodo(false);
                      }
                      handleAddTodo();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAddTodo();
                      }
                    }}
                    autoFocus
                    style={{ flex: 1, width: 'calc(100% - 20px)', height: '30px' }}
                  />
                  <div onClick={handleAddTodo} style={{ display: 'flex', alignItems: 'center', opacity: newTodo.trim() === '' ? 0.5 : 1, pointerEvents: newTodo.trim() === '' ? 'none' : 'auto' }}>
                    &nbsp;&nbsp;

                    <SaveOutlinedIcon className="hide-on-mobile" onClick={handleAddTodo} disabled={newTodo.trim() === ''} />

                  </div>
                </>
              ) : (
                <></>

              )}

            </div>
            {deletingTodoIndex !== null && (
              <div className="confirmation-dialog" style={{ visibility: isAlertOpen ? 'visible' : 'hidden' }}>
                {/* Confirmation dialog content */}
                <Typography variant="h6" component="h2">Delete this item?</Typography>
                <Typography variant="subtitle2" component="h6">Name : {deletingTodoText}</Typography>
                <Divider />

                <div style={{ marginTop: '32px' }}>
                  <Typography variant="body1">This action cannot be undone!</Typography>
                </div>


                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
                  &nbsp;
                </div>

                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                  <Button variant="outlined" ref={cancelDeleteTodoButtonRef} onClick={handleCancelDeleteTodo} onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleCancelDeleteTodo();
                    }
                  }}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleConfirmDeleteTodo(deletingTodoIndex)}
                    style={{ marginLeft: 'auto' }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleConfirmDeleteTodo(deletingTodoIndex);
                      }
                    }}>
                    Delete
                  </Button>
                </div>
              </div>
            )}
            <div style={{ marginTop: '5px', marginBottom: '5px', visibility: isAlertOpen ? 'hidden' : 'visible' }}>
              <Suspense fallback={<Loading />}>

                <List>
                  {/* {todos.map((todo, index) => ( */}
                  {todos.filter(todo => (todo.active || todo.active === 1)).map((todo, index) => (
                    <ListItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox checked={todo.completed} onChange={() => handleToggleTodo(index)} />
                      {editingTodoIndex === index ? (
                        <>
                          <TextField
                            value={todo.description}
                            onChange={(e) => {
                              const updatedTodos = [...todos];
                              updatedTodos[index].description = e.target.value;
                              setTodos(updatedTodos);
                            }}
                            onBlur={() => {
                              handleSaveTodo(index);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSaveTodo(index);
                              }
                            }}
                            autoFocus
                            multiline
                          />
                          <SaveOutlinedIcon className="hide-on-mobile" onClick={() => {
                            handleSaveTodo(index);
                          }} />
                        </>
                      ) : (
                        <>
                          <ListItemText primary={todo.description} onClick={() => { handleEditTodo(index) }} />
                          <Tooltip title="Edit this item" className="hide-on-mobile">
                            <IconButton aria-label="Edit this item" className="hide-on-mobile"
                              onClick={() => { handleEditTodo(index) }}>
                              <EditNoteOutlinedIcon className="hide-on-mobile"
                                onClick={() => { handleEditTodo(index) }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={`Remove this item from the list`}>
                            <DeleteOutlinedIcon onClick={() => handleDeleteTodo(index)} style={{ verticalAlign: 'middle' }} />
                          </Tooltip>
                        </>
                      )}

                    </ListItem>
                  ))}
                </List>
              </Suspense>


            </div>
            {todos.length > 0 && (
              <footer style={{ display: isDeletingTodo ? 'none' : 'flex', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {isDiscarding ? (
                    // <></>
                    <div className="confirmation-dialog">
                      {/* Confirmation dialog content */}
                      <Typography variant="h6" component="h2">{`Discard "${todoHeader}" ?`}</Typography>
                      <Divider />

                      <div style={{ marginTop: '32px' }}>
                        <Typography variant="body1">Discarded list will be gone forever !</Typography>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
                        <Button variant="outlined" onClick={handleCancelDiscard}>
                          Cancel
                        </Button>
                        <Button variant="contained" color="error" onClick={handleConfirmDiscard} style={{ marginLeft: 'auto' }}>
                          Discard forever!
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {todos.length > 0 && (
                        <ButtonGroup variant="outlined" aria-label="Loading button group">
                          <Tooltip title="Archive this list">
                            <IconButton aria-label="Archive this list" onClick={handleArchiveTodoList}>
                              {/* <InventoryOutlinedIcon onClick={handleArchiveTodoList} /> */}
                              <Button onClick={handleArchiveTodoList}
                                variant="contained"
                                color="success">
                                Archive
                              </Button>
                            </IconButton>
                          </Tooltip>
                          &nbsp;&nbsp;&nbsp;
                          <Tooltip title={`Discard list and its contents`}>
                            <IconButton aria-label={`Discard "${todoHeader}"`} onClick={handleDiscardTodoList}>

                              <Button
                                variant="outlined"
                                color="error">
                                Discard
                              </Button>
                            </IconButton>
                          </Tooltip>

                        </ButtonGroup>
                      )}
                    </>
                  )}
                </div>
              </footer>
            )}
          </div>



        </>
      )}
    </>
  );
}