import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Divider } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import makeStyles from '@mui/styles/makeStyles';

//import WatchOutlinedIcon from '@mui/icons-material/WatchOutlined';

import CurrentDate from '../Utils/CurrentDate';
import { StoreContext } from '../Hooks/store';

import { notify } from "../Notifications";
import Todos from './Todos';

import sqlitedb from '../sqlitedb';

import { v4 as uuidv4 } from "uuid";
import { hashCode } from '../Utils/hashCode';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';

const useStyles = makeStyles((theme) => ({
    iconwidth: {
        minWidth: '35px !important',
    },
    menuwidth: {
        width: 'inherit'
    },

    menuDiv: { marginLeft: '10px' },
    activitiesMenu: {
        fontSize: '12pt', 
        verticalAlign: 'middle',
        marginBottom: '5px',
        display: 'inline-block'
    },

    tctitle: {
        fontFamily: 'Montserrat', fontSize: '11pt',
        backgroundColor: '#00416e', color: '#fff',
        textAlign: 'center'
    },
    b1: {
        backgroundColor: '#fff',
        minWidth: '320px',
        marginLeft: '-5px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function TodoList(props) {

    const {
        modaldialog,
        todolistheader,
        todolistheaderid,
        todolistsaved,
        thisuserid
    } = React.useContext(StoreContext);

    const { trackInteraction } = useUserInteractionTracker();

    const [, setLoadedmodal] = modaldialog;
    const [todoHeader, setTodoHeader] = todolistheader;
    const [todolistid, settodolistid] = todolistheaderid;
    const [todos, setTodos] = todolistsaved;
    const [userid, setUserid] = thisuserid;


    const [menucolor] = useState(props.rendercolor)

    

    //const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const classes = useStyles();

    //let workouticon = menucolor === 'primary' ? <WatchOutlinedIcon color="primary" /> : <WatchOutlinedIcon style={{ color: menucolor }} />

    const [open, setOpen] = useState(false);

    function handleOnClose() {
        saveTodolist(todos)
        setLoadedmodal("")
        setOpen(false)
        trackInteraction('button_click', { buttonText: 'Todolist.close' });
    }

    const getTodolistHeaderId = () => {
        if (!todolistid || todolistid === '') {
          let todoListHeaderId = hashCode(uuidv4());
          console.log(`getTodolistHeaderId.todoListHeaderId=${todoListHeaderId}`);
          settodolistid(todoListHeaderId); // note that this updates todolistid
        }
      }

    const assignListIdIfMissingInTodos = todos => {
        getTodolistHeaderId();
        console.log(`in assignListIdIfMissing. todolistid= ${todolistid}. todos= ${todos} `)
        console.log('todolistid')
        console.log(todolistid)
        console.log('todos')
        console.log(todos)
        
        return Array.isArray(todos) ? todos.map(todo => ({
          ...todo,
          list_id: todo.list_id || todolistid,
        })) : [];
      };

    async function saveTodolist(todoObj) {
        trackInteraction('form_submit', { formName: 'Todolist' });
        if(!todoObj || todoObj.length < 1){
            notify('To-do list empty. Nothing to save')
            return;
        }
        try {
            assignListIdIfMissingInTodos('in saveTodolist ' + todoObj);
            console.log(todoObj)
            //await sqlitedb.saveTodos(todoObj);
            await sqlitedb.saveTodos(todoObj, userid, todolistid)
            notify('To-do list saved!')
        } catch (error) {
            notify('Error saving to-do list to remote server')
            console.log('saveTodolist reported error below...');
            console.log(error)
        }
    }

    function handleOnOpen() {
        notify('Coming soon... get data from remote store. Merge it with local cache over-writing remote dups with local updates.')
        setLoadedmodal("Todolist")
        setOpen(true)
        trackInteraction('page_view', { pageName: 'Todolist' });
    }

    return (<>
        <div className={classes.menuDiv} onClick={handleOnOpen}>
            {/* {workouticon} */}
            <span className={classes.activitiesMenu}>&nbsp;&nbsp;Todos</span>
        </div>
        <Dialog open={open} onClose={handleOnClose}>
            <DialogTitle className={classes.tctitle} onClose={handleOnClose}>
                {/* <CurrentDate options={dateOptions} /> */}
                {todoHeader}
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleOnClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.b1}>
                <Todos />
            </DialogContent>

        </Dialog>
    </>);
}

