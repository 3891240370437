import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";


import styled from "styled-components";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
//import { notify } from "../Notifications";

import { StoreContext } from '../Hooks/store';
import LocationPopup from './LocationPopup'
import CustomizedDialogs from '../LocationPopup'


const Wrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
`;

// TODO use an API to bring the markers
const markers = [
  {
    "coords": [47.514500, -122.165680],
    "name": "The Mighty Sierra Heights Elementary School",
    "address": "2501 Union Ave NE, Renton, WA 98059",
    "description": "Temporarily closed from March 2020 due to covid-19."
  },
  {
    "coords": [47.501791, -122.152858],
    "name": "Hazen High School",
    "address": "1101 Hoquiam Ave NE, Renton, WA 98059",
    "description": "Had a great senior year here. The teachers are awesome and leave a big impact on you. The students are a different story. Some are really nice while some are bullies that get away with anything they want and the Dean of students doesn't do anything to solve it. He is really terrible at his job. Other than that, i really liked Hazen."
  },
  {
    "coords": [47.503566, -122.157299],
    "name": "Albertsons",
    "address": "4621 NE Sunset Blvd, Renton, WA 98059",
    "description": "Good overall selection and really good fresh baked french bread."
  },
  {
    "coords": [47.50442, -122.163481],
    "name": "Renton Licensing Services Office",
    "address": "1314 Union Ave NE, Renton, WA 98059",
    "description": "I’m completely blown away at the customer service and minimal wait time."
  }
];


function Map() {

  // create map
  const mapRef = useRef(null);

  let [counter] = useState(0);
  let r = Math.floor(2340 * 0.0006213712);
  let [radius, setRadius] = useState(r);
  let msg = `You are within a radius of ${Math.floor(
    2340 * 0.0006213712
  )} mile(s) from this point...`;
  let [popupMsg, setPopupMsg] = useState(msg);
  const [onLocationWiredUp, setOnLocationWiredUp] = useState(false);

  const { mapdialog, mapdialogopen } = React.useContext(StoreContext);
  const [dialogContent, setDialogContent] = mapdialog;
  const [dialogOpen, setDialogOpen] = mapdialogopen;





  const [markerPosition, setMarkerPosition] = useState({
    lat: 47.66,
    lng: -122.33
  });

  const defaultMarker =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=";

  const defaultMarkerShadow =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAQAAAACach9AAACMUlEQVR4Ae3ShY7jQBAE0Aoz/f9/HTMzhg1zrdKUrJbdx+Kd2nD8VNudfsL/Th///dyQN2TH6f3y/BGpC379rV+S+qqetBOxImNQXL8JCAr2V4iMQXHGNJxeCfZXhSRBcQMfvkOWUdtfzlLgAENmZDcmo2TVmt8OSM2eXxBp3DjHSMFutqS7SbmemzBiR+xpKCNUIRkdkkYxhAkyGoBvyQFEJEefwSmmvBfJuJ6aKqKWnAkvGZOaZXTUgFqYULWNSHUckZuR1HIIimUExutRxwzOLROIG4vKmCKQt364mIlhSyzAf1m9lHZHJZrlAOMMztRRiKimp/rpdJDc9Awry5xTZCte7FHtuS8wJgeYGrex28xNTd086Dik7vUMscQOa8y4DoGtCCSkAKlNwpgNtphjrC6MIHUkR6YWxxs6Sc5xqn222mmCRFzIt8lEdKx+ikCtg91qS2WpwVfBelJCiQJwvzixfI9cxZQWgiSJelKnwBElKYtDOb2MFbhmUigbReQBV0Cg4+qMXSxXSyGUn4UbF8l+7qdSGnTC0XLCmahIgUHLhLOhpVCtw4CzYXvLQWQbJNmxoCsOKAxSgBJno75avolkRw8iIAFcsdc02e9iyCd8tHwmeSSoKTowIgvscSGZUOA7PuCN5b2BX9mQM7S0wYhMNU74zgsPBj3HU7wguAfnxxjFQGBE6pwN+GjME9zHY7zGp8wVxMShYX9NXvEWD3HbwJf4giO4CFIQxXScH1/TM+04kkBiAAAAAElFTkSuQmCC";

  // var popup = L.popup();
  // function onMapClick(e) {
  //   popup
  //     .setLatLng(e.latlng)
  //     .setContent("You clicked the map at " + e.latlng.toString())
  //     .openOn(mapRef.current);
  // }

  var defaultIcon = new L.Icon({
    iconUrl: defaultMarker,
    iconAnchor: [12, 41],
    shadowUrl: defaultMarkerShadow
  });

  useEffect(() => {
    // create map
    mapRef.current = L.map("map", {
      center: [47.66, -122.33],
      zoom: 10,
      layers: [
        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          detectRetina: true,
          maxZoom: 20,
          maxNativeZoom: 17,
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        })
      ],
      zoomControl: true
    });
    L.control.scale().addTo(mapRef.current);
  }, []);

  // add marker
  const markerRef = useRef(null);
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setLatLng(markerPosition);
      markerRef.current.setPopupContent(popupMsg);
    } else {
      markerRef.current = L.marker(markerPosition, { icon: defaultIcon })
        .addTo(mapRef.current)
        .bindPopup(popupMsg);
    }
  }, [markerPosition, defaultIcon]);

  // const CustomReactPopup = (props) => {
  //   return (
  //     <div style={{ fontSize: "24px", color: "black" }}>
  //       <p>{props.title}</p>
  //     </div>
  //   );
  // };

  const iconSvg = new L.Icon({
    iconUrl: require('./location_marker.svg'),
    iconRetinaUrl: require('./location_marker.svg'),
    iconAnchor: [20, 15],
    //popupAnchor: null,
    //shadowUrl: defaultMarkerShadow,
    //shadowSize: null,
    //shadowAnchor: null,
    iconSize: new L.Point(40, 40),
    //className: 'leaflet-div-icon'
  });
  

  useEffect(() => {

    /*
        markerRef.current = L.marker([47.514500, -122.165680], { icon: iconSvg })
          .addTo(mapRef.current)
          .bindPopup(ReactDOMServer.renderToString(<LocationPopup color='#8CC63F' title="The Mighty Sierra Heights Elementary School" address="2501 Union Ave NE, Renton, WA 98059" description="Temporary closed" />));
    */
    // markerRef.current = L.marker([47.501791, -122.152858], { icon: iconSvg })
    //   .addTo(mapRef.current)
    //   .bindPopup(ReactDOMServer.renderToString(<LocationPopup color='#FBB03B' title="Hazen High School" address="1101 Hoquiam Ave NE, Renton, WA 98059" description="Had a great senior year here. The teachers are awesome and leave a big impact on you. The students are a different story. Some are really nice while some are bullies that get away with anything they want and the Dean of students doesn't do anything to solve it. He is really terrible at his job. Other than that, i really liked Hazen." />));
    //.bindPopup('Sierra Heights');

    // function markerOnClick(e) {
    //   let attributes = e.target.properties;
    //   setDialogContent(attributes);
    //   setDialogOpen(true);
    // }

    // https://stackoverflow.com/questions/16626735/how-to-loop-through-an-array-containing-objects-and-access-their-properties
    markers.forEach(function (m) {
      markerRef.current = L.marker(m.coords, { icon: defaultIcon })
      markerRef.current.properties = {}
      markerRef.current.properties.name = m.name
      markerRef.current.properties.address = m.address
      markerRef.current.properties.description = m.description
      markerRef.current.addTo(mapRef.current)
      markerRef.current.on('click', function (e) {
        let attributes = e.target.properties;
        setDialogContent(attributes)
        setDialogOpen(true)
        mapRef.current.setView(e.latlng, 17)
      })

      markerRef.current.on('mouseover', function (e) {
        const popup = L.popup()
          .setLatLng(e.latlng)
          .setContent(m.name + ' ' + m.address)
          .openOn(mapRef.current)
      })
    })

    //ReactDOMServer.renderToString(<LocationPopup color='#8CC63F' title="The Mighty Sierra Heights Elementary School" address="2501 Union Ave NE, Renton, WA 98059" description="Temporary closed" />)

    //http://jsfiddle.net/ve2huzxw/74/      
    //https://gis.stackexchange.com/questions/172508/add-an-event-listener-on-a-marker-in-leaflet

    mapRef.current.on('click', function (e) {
      const popup = L.popup()
      popup
        .setLatLng(e.latlng)
        .setContent("You clicked the map at " + e.latlng.toString())
        .openOn(mapRef.current)
    })
  //}, [iconSvg])
  }, [defaultIcon])
  


  function onLocationFound(e) {
    setMarkerPosition({
      lat: e.latitude,
      lng: e.longitude
    });

    if (e && e.accuracy) {
      let miles = Math.floor(e.accuracy * 0.0006213712);
      let msg = `You are now within a radius of ${miles} mile(s) from this point: ${e.latitude}, ${e.longitude}`;
      setRadius(miles);
      setPopupMsg(msg);
      const now = Date.now();
      //console.log(location_payload);
      //notify(msg);
    }
  }

  function onLocationError() {
    let currentDate = "[" + new Date().toUTCString() + "] ";
    console.log(currentDate + ": in onLocationError");
    //notify(e.Message);
  }

  return <>
    <CustomizedDialogs payload={dialogContent} />
    <Wrapper width="100vw" height="95vh" id="map" />
  </>
}

export default Map;